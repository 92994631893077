.ignitor__header_wrap {
    position: relative;
    z-index: 1;
}

.ignitor__header_wrap:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/body_01_lines.png);
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.1;
}

.ignitor__header {
    height: auto;
    display: flex;
    flex-direction: row;
}

.ignitor__header-content {
    width: 30%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 2rem;
    margin-right: 1rem;
}

.ignitor__header-content h1 {
    position:relative;
    margin-top: 160px;
    color: #F85124;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 66px; /* 137.5% */
}

.ignitor__header-content h3{
    position:relative;
    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: auto;
    padding: 6px 24px;
    border-radius: 8px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #FB5124;
    border: 2px solid #FB5124;
}

.ignitor__header-content p {
    position:relative;
    margin-top: 24px;
    margin-bottom: 0px;
    margin-left: 3px;
    width: 100%;
    color: #000000;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px; /* 135.714% */
}

.ignitor__header-content__input {
    width: 100%;
    margin: 2rem 0 0rem;

    display: flex;
    flex-direction: row;
    z-index: 2;
}

.ignitor__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    background: var(--color-bg);
    border: 2px solid var(--color-bg);
    padding: 0 1rem;
    outline: none;
    color: #000000;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    z-index: 2;
}

.ignitor__header-content__input button {
    flex: 1.5;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: #ff2f00;
    border: 2px solid #FF4820;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-radius: 5px 5px 5px 5px;
    z-index: 2;
}

.ignitor__header-image {
    flex: 3;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
}

.ignitor__header-image img {
    width: 100%;
    opacity: 1.0;
    position: relative;
    z-index: 2;
}

@media screen and (max-width: 1050px) {
    .ignitor__header {
        flex-direction: column;
    }

    .ignitor__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .ignitor__header-content h1 {
        width: 80%;  
        padding: auto;
        font-size: 48px;
        line-height: 60px;
    }

    .ignitor__header-content p {
        width: 80%;  
        font-size: 16px;
        line-height: 24px;
    }
    
    .ignitor__header-content__input input,
    .ignitor__header-content__input button {
        width: 60%;  
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .ignitor__header-content {
        width: 100%;
        margin: 0 0 3rem;
    }
    .ignitor__header-content h1 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 800;
        font-size: 40px;
        margin-top: 5px;
        width: 100%;  
        line-height: 48px;
    }

    .ignitor__header-content p {
        width: 100%;
        font-size: 14px;
        font-weight: 100;
        line-height: 24px;
    }
    .ignitor__header-content__input {
        width: 100%;
        margin: 2rem 0 0rem;
        padding: 2px 2px;
    }

    .ignitor__header-content__input input {
        width: 100%;
        font-size: 11px;
        line-height: 16px;
    }
    .ignitor__header-content__input button {
        width:100%;
        font-size: 12px;
        line-height: 16px;
    }
}