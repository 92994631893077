.ignitor__features {
    min-height: 600px;
    background-blend-mode: color-burn;
    display: flex;
    flex-direction: row;
    padding: 12px;
    align-items: center;
    width: 100%;
}

.ignitor__features-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    text-align: left;
    margin-right: 5rem;
}

.ignitor__features-heading h1 {
    color: #ff7a33;
    font-size: 48px;
    line-height: 48px;
    font-weight: 600;
    font-family: var(--font-family);
}

.ignitor__features-heading h2 {
    color: #ff7a33;
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.ignitor__features-heading p {
    color: var(--color-subtext);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);

    margin-top: 2rem;
}

.ignitor__features-container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 990px) {
    .ignitor__features {
        flex-direction: column;
    }

    .ignitor__features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .ignitor__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }
}

.features {
    background-color: #fff;
    min-height: 800px;
    width: 100%;
    background-blend-mode: color-burn;
    display: flex;
    flex-direction: row;
    padding: 12px;
    align-items: center;
}

.feature-box-title {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 0px;
}

.feature-box{
    display: flex;
    flex-direction: column;
    padding: 0px;
}

.feature-box-title h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 48px;
    padding-right: 40px;
    color: #F85124;
    margin-left: 100px;
    box-shadow: 15px 15px 4px 0px rgba(255, 180, 180, 0.25);

}

.feature-box-text{
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    padding: 48px;

}

.feature-box-text img{
  width: 80px;
  height: 12px;
  margin-top:0px;
  margin-left: 48px;
  margin-bottom: 4px;
  margin-right: 48px;

}

.feature-box-text h2{
    position: relative;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: 28px;
    color: #000000;
    margin-top:0px;
    margin-left: 48px;
    margin-bottom: 0px;
    margin-right: 48px;
     
}
.feature-box-text p{
    position: relative;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    color: #000000;
    margin-top:4px;
    margin-left:48px;
    margin-bottom: 0px;
    margin-right: 120px;


}

@media screen and (max-width: 650px) {
    .features {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .feature-box-title{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .feature-box {
        width: 90%;
        display: flex;
        flex-direction: column;
    }

}


@media screen and (max-width: 490px) {
    .features {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .feature-box-title{
        width: 90%;
        display: flex;
        flex-direction: column;
    }

    .feature-box-title h1{
        width: 60%;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 800;
        font-size: 40px;
        align-items: center;
        margin: 5px 5px 5px 5px;
        color: #F85124;
        box-shadow: 15px 15px 4px 0px rgba(255, 180, 180, 0.25);
    }

    .feature-box {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .feature-box-text img{
        width: 20%;
        margin-top:0px;
        margin-left: 48px;
        margin-bottom: 4px;
        margin-right: 48px;
    
    }
    
    .feature-box-text h2{
        position: relative;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 500;
        font-size: 28px;
        color: #000000;
        margin: auto;
        
    }
    .feature-box-text p{
        position: relative;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 18px;
        color: #000000;
        margin: auto;
    }
}