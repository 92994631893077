.product {
    background: url(../../assets/body_02_lines.png),#f7f7fade 50% / cover repeat;
    background-blend-mode: color-burn;
    display: flex;
    flex-direction: column;
    align-items: center;
    /*
    border: 3px solid #000000;
    */
}

.product-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 24px 64px 24px 24px;
}

.product-box h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 48px;
    color: #F85124;
    text-align: center;
    padding: 12px;
    box-shadow: 15px 15px 4px 0px rgba(255, 180, 180, 0.25);
}

.product-box-text{
    display: fixed;
    flex-direction: column;
    Width: 480px;
    margin-right: 120px;
    margin-left: 120px;
    align-items: center;
    margin:auto;
}

.product-box-text h2{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: #F85124;
    margin:auto;
    text-align: left;
    padding: 24px 12px 12px 12px;
}

.product-box-text p{
    margin-top: 0px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    text-align: Left;
    padding: 12px 12px 12px 12px;

}

.product-box img{
    padding: 0px 0px 0px 0px;
    margin-right: 24px;
    margin-left: 24px;
    height: 400px;
    justify-content: flex-end;
    align-items: center;
    scale: 0.8;
}



@media screen and (max-width: 650px) {
    .product {
        flex-direction: column;
        width: 100%
    }
    .product-box{
        display: flex;
        flex-direction: column;
        padding: 0px 0px 0px 0px;
    }
}

@media screen and (max-width: 490px) {
    .product {
        flex-direction: column;
        width: 100%;
    }

    .product-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0px 0px 0px 0px;
    }

    .product-box h1{
        width: 90%;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 800;
        font-size: 40px;
        color: #F85124;
        text-align: left;
        padding: 12px;
        box-shadow: 15px 15px 4px 0px rgba(255, 180, 180, 0.25);
    }
    
    .product-box-text{
        display: fixed;
        flex-direction: column;
        Width: 90%;
        margin-right: 0px;
        margin-left: 0px;
        align-items: center;
        margin:auto;
    
    }
    
    .product-box-text h2{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 200;
        font-size: 32px;
        color: #F85124;
        margin:auto;
        text-align: left;
        padding: 24px 12px 12px 12px;
    }
    
    .product-box-text p{
        width: 100%;
        margin-top: 5px;
        margin-bottom: ;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 16px;
        text-align: Left;
        padding: 12px 12px 12px 12px;
    
    }
    
    .product-box img{
        scale: 0.3;
        padding: 0px 0px 0px 0px;
        margin-right: 12px;
        margin-left: 12px;
        justify-content: flex-end;
        align-items: center;
    }
}