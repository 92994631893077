.team {
    background: url(../../assets/body_02_lines.png),#f7f7fade 50% / cover repeat;
    min-height: 600px;
    background-blend-mode: color-burn;
    display: flex;
    flex-direction: row;
    padding: 12px;
    align-items: center;
}

.team-box{
    width: 35%;
    display: flex;
    flex-direction: column;
    padding: 1px;
}

.team-box h1{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 500;
    font-size: 48px;
    width: 70%;
    padding-right: 40px;
    color: #F85124;
    margin-left: 100px;
    box-shadow: 15px 15px 4px 0px rgba(255, 180, 180, 0.25);

}

.team-box-text{
    display: flex;
    flex-direction: column;
    margin-left: 48px;
    padding: 12px 48px 12px 48px;


}

.team-box-text img{
  width: 70%;
  margin-top:12px;
  margin-left: 48px;
  margin-bottom: 0px;
  margin-right: 48px;
  border-radius: 16px 16px 0px 0px;
}

.team-box-text h2{
    position: relative;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 300;
    font-size: 20px;
    color: #ffffff;
    margin-top:-65px;
    margin-left: 48px;
    margin-bottom: auto;
    margin-right:48px;
    padding: 2px 24px 0px 24px;
    width: 70%;
    background-color: #F85124;
   
     
}

.team-box-text h3{
    position: relative;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 100;
    font-size: 16px;
    color: #ffffff;
    margin-top:0px;
    margin-left: 48px;
    margin-bottom: auto;
    margin-right:48px;
    padding: 2px 24px 2px 24px;
    width:70%;
    background-color: #F85124;
}
.team-box-text p{
    position: relative;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 13px;
    color: #000000;
    margin-top: 0px;
    margin-left: 48px;
    margin-bottom: 12px;
    margin-right: 48px;
    padding: 2px 24px 2px 24px;
    width: 70%;
    height: auto;
    background-color: #ffffff;
    border-radius: 0px 0px 16px 16px ;
}

@media screen and (max-width: 650px) {
    .team {
        width: 100%;
        flex-direction: column;
    }

    .team-box {
        display: flex;
        width: 50%;
        flex-direction: column;
    }
}

@media screen and (max-width: 490px) {
    .team {
        width: 100%;
        flex-direction: column;
    }

    .team-box {
        display: flex;
        width: 100%;
        flex-direction: column;
    }
    .team-box h1 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 800;
        font-size: 40px;
        width: 70%;
        padding-right: 40px;
        color: #F85124;
        margin-left: 5px;
        box-shadow: 15px 15px 4px 0px rgba(255, 180, 180, 0.25);    
    
    }

    
    .team-box-text{
        width: 90%;
        display: flex;
        flex-direction: column;
        margin: 5px 5px 5px 5px;
        padding: 2px 5px 2px 5px;

    }

    .team-box-text img{
    width: 70%;
    margin-top:12px;
    margin-left: 48px;
    margin-bottom: 0px;
    margin-right: 48px;
    border-radius: 16px 16px 0px 0px;
    }

    .team-box-text h2{
        position: relative;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 300;
        font-size: 20px;
        color: #ffffff;
        margin-top:-65px;
        margin-left: 48px;
        margin-bottom: auto;
        margin-right:48px;
        padding: 2px 24px 0px 24px;
        width: 70%;
        background-color: #F85124;
    
        
    }

    .team-box-text h3{
        position: relative;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 100;
        font-size: 16px;
        color: #ffffff;
        margin-top:0px;
        margin-left: 48px;
        margin-bottom: auto;
        margin-right:48px;
        padding: 2px 24px 2px 24px;
        width:70%;
        background-color: #F85124;
    }
    .team-box-text p{
        position: relative;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 13px;
        color: #000000;
        margin-top: 0px;
        margin-left: 48px;
        margin-bottom: 12px;
        margin-right: 48px;
        padding: 2px 24px 2px 24px;
        width: 70%;
        height: auto;
        background-color: #ffffff;
        border-radius: 0px 0px 16px 16px ;
    }
}