.ignitor__navbar {
    display: flex;
    background-color: #ffffffc7;
    justify-content: space-between;
    align-items: center;
    top: 0;
    padding: 0rem 6rem;
}

.ignitor__navbar-links {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ignitor__navbar-links_logo {
    margin-right: 2rem;
    width: 250px;
}

.ignitor__navbar-links_logo img {
    width: 250px;

}

.ignitor__navbar-links_container {
    display: flex;
    flex-direction: row;
}


.ignitor__navbar-links_container p,
.ignitor__navbar-menu_container p {
    color: #000000;
    float: left;
    text-align: center;
    padding: 0px 2px ;
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: 500;

    margin: 0 1rem;
    cursor: pointer;
}

.ignitor__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.ignitor__navbar-menu svg {
    cursor: pointer;
}

.ignitor__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 20px;
    margin-top: 1rem;
    min-width: 150px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
    z-index: 2;
}



.ignitor__navbar-menu_container-links {
    display: flex;
    flex-direction: column;
}

.ignitor__navbar-menu_container-links p {
    margin: 10px 5px;
    text-align: end;
}


@media screen and (max-width: 1050px) {
    .ignitor__navbar-links_container {
        display: none;
    }

    .ignitor__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 550px) {
    .ignitor__navbar {
        padding: 2rem;
    }

    .ignitor__navbar-menu_container {
        top: 20px;
    }

}

  